
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const base = namespace("base");
const supplier = namespace("supplier");
const marketing = namespace("marketing");
@Component({})
@Table("loadListData")
export default class UpkeepAlert extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryCardTemplateList;
  @marketing.Action queryRemindRecordPage; //查询保养
  @marketing.Action batchManualPush;
  @marketing.Action queryRemindSetting;
  @base.Action queryStoreMessageBalance;
  @marketing.Action batchRepeatManualPush;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  orderStatusList: any[] = [];
  cardTemplateTypeList: any = [];
  settlementList: any = [];
  cardTemplateList: any[] = [];
  searchForm = {
    customerName: "",
    mobile: "",
    remindWay: "",
    remindStatus: ""
  };
  //计算属性
  get searchList() {
    return [
      {
        label: t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "customerName"
      },
      {
        label: t("v210801.contact-number"),
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: t("v210831.reminder-mode"),
        type: "select",
        selectList: this.orderStatusList,
        value: "",
        prop: "remindWay"
      },
      {
        label: t("v210831.reminder-status"),
        type: "select",
        selectList: this.settlementList,
        value: "",
        prop: "remindStatus"
      }
    ];
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryRemindRecordPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.cardTemplateList = data.data.list;
      return data;
    });
  }
  created() {
    this.init();
  }
  mounted() {}
  async init() {
    this.loadListData();
    this.getDictionaryList("remind_way").then(data => {
      this.orderStatusList = data;
    });
    this.getDictionaryList("remind_status").then(data => {
      this.settlementList = data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  async surePopconfirm(row) {
    // 先看配置的推送渠道，如果有短信，再检查短信余量，不足直接拦截提示更改推送渠道
    // 查询保养配置
    this.queryRemindSetting().then(res => {
      if (this.$safeValue(res, "data.notifyMethods", []).includes("weixin")) {
        // 查短信数
        this.queryStoreMessageBalance().then(data => {
          if (Number(data.data.smsBalance) <= 0) {
            this.$message.success(t("v210831.the-sms-balance-please-change-other"));
          } else {
            if (row.remindStatus === 1) {
              this.querySetDetail(row);
            } else {
              this.repeatPush(row);
            }
          }
        });
      } else {
        if (row.remindStatus === 1) {
          this.querySetDetail(row);
        } else {
          this.repeatPush(row);
        }
      }
    });
  }
  async repeatPush(row) {
    const params = {
      remindCodeList: [row.remindCode]
    };
    const res = await this.batchRepeatManualPush(params);
    if (!res.data) {
      this.$message.success(t("v210831.reminder-message-pushed"));
      this.loadListData();
    } else {
      this.$message.warning(t("v210831.failed-to-push"));
      this.loadListData();
    }
  }
  async querySetDetail(row) {
    const params = {
      remindCodeList: [row.remindCode]
    };
    const res = await this.batchManualPush(params);
    if (!res.data) {
      this.$message.success(t("v210831.reminder-message-pushed"));
      this.loadListData();
    } else {
      this.$message.warning(t("v210831.failed-to-push"));
      this.loadListData();
    }
  }
  goSetUpkeep() {
    this.$router.push("/marketing/upkeep-alert-set");
  }
  //   ///
}
